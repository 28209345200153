







































































































































































import BaseMenuItem from '@/components/BaseMenuItem.vue';
import ProfileConnector from '@/connector/Profile.vue';
// eslint-disable-next-line import/no-cycle
import LaunchGameMixin from '@/mixins/launchGameMixin';
import { Mixins, Component } from 'vue-mixin-decorator';
import SexyIcon from '@/components/SexyIcon.vue';
import BaseSlider from '@/components/BaseSlider.vue';

@Component({
  components: {
    BaseMenuItem,
    ProfileConnector,
    SexyIcon,
    BaseSlider,
  },
})
export default class Dashboard extends Mixins<LaunchGameMixin>(LaunchGameMixin) {
  // eslint-disable-next-line class-methods-use-this
  get menus(): Array<{ icon: string; title: string; to?: string; }> {
    return [
      { icon: 'far fa-user', title: 'ข้อมูลสมาชิก', to: 'PlayerInfo' },
      { icon: 'fas fa-gamepad', title: 'เล่นเกมส์', to: 'Games' },
      { icon: 'fas fa-wallet', title: 'ฝากเงิน', to: 'Deposit' },
      { icon: 'fas fa-money-bill', title: 'ถอนเงิน', to: 'Withdraw' },
      { icon: 'fas fa-history', title: 'ประวัติ', to: 'Transaction' },
      { icon: 'fas fa-gift', title: 'รับโปรโมชั่น', to: 'JoinPromotion' },
      { icon: 'fas fa-users', title: 'ระบบแนะนำ', to: 'Affiliate' },
      { icon: 'fas fa-redo', title: 'รับยอดเสีย', to: 'Cashback' },
      { icon: 'fas fa-dharmachakra', title: 'กงล้อนำโชค', to: 'FortuneWheel' },
      { icon: 'fas fa-power-off', title: 'ออกจากระบบ', to: 'Logout' },
    ];
  }

  public gameUrl: string = '';

  generateLinkByRecentGame(type, data) {
    if (type === 'betworld') {
      return this.generateBetworldUrl();
    }
    if (type === 'fishing') {
      return this.generateFishGameUrl();
    }
    if (type === 'casino' || type === 'bikini') {
      return this.generateGameLobbyUrl(data.gameId, data.type);
    }
    if (type === 'slot') {
      return this.generateGameLobbyUrl('26');
    }
    if (type === 'pgslot') {
      return this.generatePgSlotUrl(data.provider, data.gameId);
    }
    return '';
  }
}
